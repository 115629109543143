import React from "react";
import InfoFile from "./FileCard/File";
import styles from "./InfoFolder.module.css";

const InfoFolder = ({ files, setTier }) => {
  const containerRef = React.createRef();
  const [height, setHeight] = React.useState(0);
  const [aniData, setAniData] = React.useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);


  React.useEffect(() => {
    if (containerRef.current) setHeight(containerRef.current?.clientHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (containerRef.current) {
      window.addEventListener("resize", () => {
        if (containerRef.current?.clientHeight) {
          setHeight(containerRef.current?.clientHeight);
        }
      });
    }
    return () => {
      window.addEventListener("resize", null);
    };
  }, [containerRef]);

  React.useEffect(() => {
    let data = [];
    Array.isArray(files) &&
      files.forEach(() => {
        data.push("none");
        setSelectedIndex(-1);
      });
    setAniData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCardClick = (index) => {
    setTier({
      tier: files[index].title,
      weight: files[index].weight,
      stake: files[index].stake,
    });
    let data = [];
    if (selectedIndex !== -1 && index === selectedIndex) {
      Array.isArray(files) &&
        files.forEach(() => {
          data.push("none");
        });
      setAniData(data);
      setSelectedIndex(-1);
    } else {
      Array.isArray(files) &&
        files.forEach((e, i) => {
          if (index <= files.length / 2) {
            if (i > index) data.push("down");
            else {
              data.push("none");
            }
          } else {
            if (i === index) {
              data.push("up");
            } else {
              data.push("none");
            }
          }
        });
      setAniData(data);
      setSelectedIndex(index);
    }
  };

  return (
    <div ref={containerRef} className={styles.container}>
      {Array.isArray(files) &&
        files.map((e, i) => (
          <InfoFile
            key={i}
            index={i}
            data={e}
            height={height / (files?.length === 0 ? 1 : files.length ?? 1)}
            onClick={() => onCardClick(i)}
            selectedIndex={selectedIndex}
            move={aniData[i]}
            moveHeight={height / 6}
          />
        ))}
    </div>
  );
};

export default InfoFolder;
