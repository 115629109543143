import React from "react";
import { Typography, Col, Row, Input, Button } from "antd";
import styles from "./StakeCard.module.css";
import cx from "classnames";

import { connect } from "react-redux";
import { loading } from "../../redux/actions";

import * as Staker from "../../blockchain/contracts/staker";
import * as Token from "../../blockchain/contracts/token";
import { address } from "../../blockchain/address";

const StakeCard = (props) => {
  const minStakingAmount = 400;
  const [account, setAccount] = React.useState();
  const [amount, setAmount] = React.useState(0);
  const [validAmount, setValidAmount] = React.useState(false);

  const [balance, setBalance] = React.useState(0);
  const [staked, setStaked] = React.useState(0);
  const [approved, setApproved] = React.useState(false);

  React.useEffect(() => {
    window.ethereum.request({ method: "eth_accounts" }).then((accounts) => {
      if (!accounts.length) return;
      const account = accounts[0];
      setAccount(account);
      Promise.all([
        Token.balanceOf(account),
        Staker.stakedBalance(account),
      ]).then(([balance, staked]) => {
        setBalance(balance);
        setStaked(staked);
      });
    });
  }, [approved]);

  React.useEffect(() => {
    validateAmount(props.tier.stake);
    if (validAmount) setAmount(String(props.tier.stake));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tier]);

  const validateAmount = (amount) => {
     if (Number(amount) >= balance || Number(amount) <= staked) {
//       setAmount(balance);
      setValidAmount(balance >= minStakingAmount - staked ? true : false);
    } else if (Number(amount) < minStakingAmount - staked)
      setValidAmount(false);
    else setValidAmount(true);
  };

  const handleAmount = (e) => {
    const inputAmount = e.target.value || "";
    setAmount(inputAmount);
    validateAmount(inputAmount);
  };

  const setMaxStake = () => {
    setAmount(balance);
    validateAmount(balance);
  };

  const approveStaker = async () => {
    try {
      props.loading(true, 2);
      const approval = await Token.approve(account, address.staker, amount);
      setApproved(approval);
      // Which flow to be done click twice or once
      stakeTokens();
    } catch (error) {
      console.error(error);
    } finally {
      props.loading(false, 2);
    }
  };
  const stakeTokens = async () => {
    try {
      props.loading(true, 2);
      const staked = await Staker.stakeTokens(account, amount);
      // Changes the approval status to false once the tokens get staked, incase of failure the approval status remains true
      setApproved(!staked);
    } catch (error) {
      console.error(error);
    } finally {
      props.loading(false, 2);
    }
  };

  const unStakeTokens = async () => {
    try{
      props.loading(true, 2);
      const unstaked = await Staker.unStakeTokens(account, amount);
      // Changes the approval status to false once the tokens get staked, incase of failure the approval status remains true
      setApproved(!unstaked);
    }
    catch(error){
      console.error(error);
    }
    finally{
      props.loading(false, 2);
    }
  }

  return (
    <div className={styles.container}>
      <Typography className={styles.title}>Stake now</Typography>
      {/* <Typography className={styles.subTitle}>
        HOW MUCH DO YOU WANT TO STAKE
      </Typography> */}
      <div className={styles.balanceBox}>
        <Row justify="space-between" align="middle">
          <Col>
            <Typography className={styles.balance}>
              Balance: {balance}
            </Typography>
          </Col>
          <Col>
            <Typography className={styles.maxUse} onClick={setMaxStake}>
              Use Max
            </Typography>
          </Col>
        </Row>{" "}
        <br />
        <Row justify="space-between" align="middle">
          <Col>
            <Typography className={styles.balance}>Staked: {staked}</Typography>
          </Col>
          <Col>
            <Typography
              className={styles.maxUse}
              onClick={Token.addTokenToMetamask}
            >
              FTML ➕
            </Typography>
          </Col>
        </Row>
      </div>

      <Typography className={styles.amount}>AMOUNT</Typography>
      <Row
        align="middle"
        justify="space-between"
        className={styles.ftmlContainer}
        wrap={false}
      >
        <Col>
          <Input
            value={amount}
            onChange={handleAmount}
            bordered={false}
            placeholder={0}
            type="number"
            className={styles.ftmlInput}
          />
        </Col>
        <Col>
          <Typography className={styles.ftml}>FTML</Typography>
        </Col>
      </Row>
      <div className={styles.infoContainer}>
        <div className={styles.subInfoContainer}>
          <Row
            style={{ width: "100%", marginBottom: "5px" }}
            className={styles.justifySpaceBetween}
          >
            <Col className={styles.infoKeySpace}>
              <Typography className={styles.infoKey}>TIER SELECTED</Typography>
            </Col>
            <Col>
              <Typography className={styles.infoValue}>
                {props.tier.tier}
              </Typography>
            </Col>
          </Row>
          <Row className={styles.justifySpaceBetween}>
            <Col className={styles.infoKeySpace}>
              <Typography className={styles.infoKey}>
                WEIGHT ALLOCATED
              </Typography>
            </Col>
            <Col>
              <Typography className={styles.infoValue}>
                {props.tier.weight}X
              </Typography>
            </Col>
          </Row>
        </div>

        <div className={styles.infoNote}>
          <Typography className={styles.note}>
            MINIMUM OF {minStakingAmount} FTML TO BE STAKED
          </Typography>
        </div>
      </div>
      <Typography className={styles.buyInfo}>
        AFTER STAKING, YOU WILL BE ELIGIBLE FOR FTML STAKING REWARDS
      </Typography>
      <Row>
        <Col xs={24} lg={12}>
          <Button
            type="primary"
            className={cx("primary-button", styles.approveButton)}
            disabled={!validAmount}
            onClick={approved ? stakeTokens : approveStaker}
          >
            STAKE FTML
          </Button>
        </Col>
        <Col xs={24} lg={12}>
          <Button
            type="primary"
            className={cx("primary-button", styles.approveButton)}
            disabled={staked<1?true:false}
            onClick={unStakeTokens}
          >
            UNSTAKE FTML
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(null, { loading })(StakeCard);
