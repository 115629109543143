import React from "react";
import { Row, Col, Button, Typography, Collapse, Tooltip } from "antd";
import Icon from "../../assets/icons/logo.png";
import BarIcon from "../../assets/icons/menu.png";
import styles from "./TopBar.module.css";
import cx from "classnames";

const TopBar = (props) => {
  const [isAtTop, setAtTop] = React.useState(true);
  const [drawer, setDrawer] = React.useState(false);

  React.useEffect(() => {
    window.onscroll = onScroll;
  }, []);

  const onScroll = () => setAtTop(window.scrollY === 0);

  return (
    <Row
      justify="space-between"
      align="middle"
      className={cx(styles.container, isAtTop ? null : styles.isNotAtTop)}
    >
      <Col>
        <Row align="middle">
          <Col>
            <img className={styles.mainIcon} src={Icon} alt="icon" />
          </Col>
          {/* <Col>
            <Typography className={styles.title}>PUBLIC SALE</Typography>
          </Col> */}
        </Row>
      </Col>
      <Col className={styles.desktopView}>
        <Row align="middle">
          <Col>
            <Typography className={styles.item}>
              Staking <span className={styles.bar}></span>
            </Typography>
          </Col>
          <Col>
            <Tooltip title="Coming soon" placement="bottom">
              <Typography className={styles.item}>
                Pool <span className={styles.bar}></span>
              </Typography>
            </Tooltip>
          </Col>
          <Col>
            <Button
              type="primary"
              shape="round"
              className={cx("primary-button", styles.connectButton)}
              onClick={props.connect}
            >
              {props.account
                ? `${props.account.slice(0, 6)}...${props.account.slice(-5)}`
                : "Connect Wallet"}
            </Button>
          </Col>
        </Row>
      </Col>
      <Col className={styles.phoneView}>
        <img
          className={styles.menuBar}
          src={BarIcon}
          alt="menu"
          onClick={() => setDrawer(() => !drawer)}
        />
      </Col>
      <Collapse
        className={styles.drawerContainer}
        accordion={true}
        style={{ left: drawer ? "0px" : "110vw" }}
      >
        <div className={styles.drawerItem}>
          <Typography className={styles.item}>Staking</Typography>
        </div>
        <div className={styles.drawerItem}>
          <Typography className={styles.item}>Presale</Typography>
        </div>
        <div className={styles.drawerItem}>
          <Button
            type="primary"
            shape="round"
            className={cx("primary-button", styles.connectButton)}
          >
            {props.account
              ? `${props.account.slice(0, 6)}...${props.account.slice(-5)}`
              : "Connect Wallet"}
          </Button>
        </div>
      </Collapse>
    </Row>
  );
};

export default TopBar;
