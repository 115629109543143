import React from "react";
import styles from "./File.module.css";
import { Typography, Row, Col } from "antd";
import cx from "classnames";

const InfoFile = ({
  data,
  index,
  height,
  onClick,
  move,
  selectedIndex,
  moveHeight,
}) => {
  return (
    <div
      className={cx(styles.container)}
      style={{
        backgroundColor: data.color,
        top: `${
          move === "up"
            ? height * index - moveHeight
            : move === "down"
            ? height * index + moveHeight
            : height * index
        }px`,
      }}
      onClick={onClick}
    >
      {/* <img
        className={cx(
          styles.backImage,
          selectedIndex === index ? styles.rotateUp : null
        )}
        src={data.image}
        alt="backImage"
      /> */}
      <div style={{ zIndex: 100 }}>
        <Row justify="space-between" align="middle">
          <Col>
            <Typography className={styles.title}>{data.title}</Typography>
          </Col>
          <Col className={styles.tier}>Tier {data.tier}</Col>
        </Row>
        <div className={styles.infoContainer}>
          <Typography className={styles.info}>{data.info_1}</Typography>
          <Typography className={styles.info}>{data.info_2}</Typography>
        </div>
      </div>
    </div>
  );
};

export default InfoFile;
