import React from "react";
import styles from "./Home.module.css";
import BlueFudge1 from "../../assets/images/fudge1.svg";
import BlueFudge2 from "../../assets/images/fudge2.svg";
import { Row, Col } from "antd";
import { StakeCard, InfoFolder } from "../../components";
import FileImage1 from "../../assets/images/Calypso.svg";

import { connect } from "react-redux";
import { loading } from "../../redux/actions";

const Home = (props) => {
  // React.useEffect(() => {
  //   props.loading(false);
  // }, [props]);

  const [tier, setTier] = React.useState({ tier: "", weight: "", stake: "" });

  const setTireHandler = (data) => {
    console.log(data);
    setTier(data);
  };

  return (
    <div className={styles.container}>
      <img className={styles.blueFudge1} src={BlueFudge1} alt="" />
      <img className={styles.blueFudge2} src={BlueFudge2} alt="" />

      <div className={styles.stakeContainer}>
        <Row>
          <Col xs={24} md={12}>
            <StakeCard tier={tier} setTier={setTier} />
          </Col>
          <Col xs={24} md={12}>
            <InfoFolder
              setTier={setTireHandler}
              files={[
                {
                  title: "Phobos",
                  tier: 1,
                  info_1: "1X Pool Weight",
                  info_2: "15,000 $FTML Staked",
                  image: FileImage1,
                  color: "#E73605",
                  weight: 1,
                  stake: 15000,
                },
                {
                  title: "Calypso",
                  tier: 2,
                  info_1: "4X Pool Weight",
                  info_2: "60,000 $FTML Staked",
                  image: FileImage1,
                  color: "#E5BF06",
                  weight: 4,
                  stake: 60000,
                },
                {
                  title: "Hyperion",
                  tier: 3,
                  info_1: "12X Pool Weight",
                  info_2: "180,000 $FTML Staked",
                  image: FileImage1,
                  color: "#E99410",
                  weight: 12,
                  stake: 180000,
                },
                {
                  title: "Oberon",
                  tier: 4,
                  info_1: "25X Pool Weight",
                  info_2: "360,000 $FTML Staked",
                  image: FileImage1,
                  color: "#044DE7",
                  weight: 25,
                  stake: 360000,
                },
                {
                  title: "Titan",
                  tier: 5,
                  info_1: "50X Pool Weight",
                  info_2: "720,000 $FTML Staked",
                  image: FileImage1,
                  color: "#91C7CC",
                  weight: 50,
                  stake: 7200000,
                },
              ]}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default connect(null, { loading })(Home);
